@import '../../../../../assets/styles/setting';
.mr-accrual-link{
  overflow: hidden;
  a{
    float: right;
    border: 1px solid #3f51b5;
    border-radius: 3px;
    padding: 3px 8px;
    font-size: 14px;
    color: #3A8AFC;
  }
}
.mr-graph-block{
  .interval-unit{
    font-size: 10px!important;
  }
  .grp-event-hd{
    padding: 15px 20px;
    background: #fafafa;
    li{
      margin-right: 100px;
      color: #BD10E0;
      font-size: 14px;
      font-family:$ibmplexRegular;
    }
  }
  .simulate-scenarios{
    position: fixed;
    bottom: 50px;
    right: 17px;
    z-index: 99;
    padding: 10px 30px;
    border-radius: 3px;
    font-family:$ibmplexSemiBold;
    font-size: 14px;
    color: #ffffff;
    background: #3f51b5;
    cursor: pointer;
  }
  .simulate-now{
    position: fixed;
    bottom: 0;
    padding: 10px 20px;
    background: #ffffff;
    right: 0;
    left: 270px;
    z-index: 998;
    box-shadow: 0 -3px 3px -3px rgba(0,0,0,0.5);
    ul{
      float: right;
      li{
        margin-left: 20px;
        cursor: pointer;
        button{
          cursor: pointer;
          padding: 10px 30px;
          border-radius: 3px;
          font-family:$ibmplexSemiBold;
          font-size: 14px;
          color: #ffffff;
          background: #3f51b5;
        }
      }
    }
  }
  .override-value{
    .changed-value{
      font-family:$ibmplexRegular;
      font-size: 16px;
      color: #2A9EF5;
    }
    .old-value{
      display: inline-block;
      font-family:$ibmplexRegular;
      font-size: 12px;
      color: #000000;
      text-decoration: line-through;
    }
  }
  .editable-field{
    position: relative;
    display: inline-block;
    .mr-error-field{
      position: absolute;
      bottom: -16px;
      font-size: 10px;
      color: #ff0000;
      font-family:$ibmplexRegular;
    }
    .Select{
      width: 120px;
    }
    .currency-symbol{
      position: absolute;
      left: 0;
      font-size: 14px;
      bottom: 7px;
      color: #B9B9B9;
    }
    .percentage-symbol{
      position: absolute;
      right: 25px;
      font-size: 14px;
      bottom: 7px;
      color: #B9B9B9;
    }
    input[type="text"]{
      border-bottom: 1px solid #E1E1E1;
      padding-left: 12px;
    }
    .field-edit-icn{
      position: absolute;
      right: 0;
      top: 5px;
      img{
        width: 20px;
      }
    }
  }
  .nav-tabs{
    .nav-item{
      .nav-link{
        padding: 10px 20px 10px;
        font-family:$ibmplexRegular;
        font-size: 15px;
        color: #8C9BA5;
        border: none;
        border-bottom: 1px solid #dee2e6;
        cursor: pointer;
        text-transform: uppercase;
        &.active{
          font-family:$ibmplexSemiBold;
          color: #3f51b5;
          border-bottom: 1px solid #3f51b5;
        }
        &:hover{
          // color: #3f51b5;
          // border-bottom: 1px solid #3f51b5;
        }
      }
    }
  }
  .review-wrapper-block{
    margin-bottom: 16px;
    ul{
      li{
        margin-right: 40px;
        cursor: pointer;
        span.label{
          font-family:$ibmplexRegular;
          font-size: 12px;
          color:#a0a0a0;
          display: block;
          margin-bottom:4px;
        }
        p{
          font-family:$ibmplexRegular;
          font-size:14px;
          color:#000000;
          line-height:14px;
        }
      }
    }
  }
  .mr-fund-rejection{
    border: 1px solid #c6cddd;
    h5{
      font-family:$ibmplexRegular;
      color:#083c7b;
      padding:15px;
      font-size: 20px;
      background: $color_fff;
      cursor: pointer;
      img{
        float:right;
        width: 16px;
      }
    }
    .card{
      border:0;
      .card-body{
        border:0;
        padding:0;

        .tab-content{
          .grp-table-wrapper{
            .grp-table-wrapper-collapse{
              background: #ffffff;
              padding: 15px;
              border-bottom: 1px solid #DFE3E6;
              position: relative;
              cursor: pointer;
              ul{
                display: flex;
                align-items: center;
                &:after, &:before{
                  width: 0;
                }
                li{
                  margin-right: 80px;
                  .program-name{
                    font-family:$ibmplexSemiBold;
                    font-size: 14px;
                  }
                  &:first-child{
                    margin-right: 20px;
                    .marker-icn{
                      width: 30px;
                    }
                  }
                }
              }
              .grp-arrow{
                position: absolute;
                top: 18px;
                right: 0;
                padding: 0 20px;
                display: inline-block;
                cursor: pointer;
                img{
                  width: 16px;
                }
              }
            }
            .card-body{
              background: #F9F9F9;
              padding: 0 20px;
              table{
                tr{
                  border-bottom: 1px solid #EBEBEB;
                  &:last-child{
                    border-bottom: none;
                  }
                  td{
                    padding: 15px 10px;
                    h6{
                      font-size: 13px;
                    }
                    input[type="text"]{
                      background: transparent;
                    }
                  }
                }
              }
            }
          }
          table{
            width: 100%;
            thead{
              th{
                padding: 10px;
                color: #083c7b;
                font-family:$ibmplexRegular;
                font-size:12px;
              }
            }
            tbody{
              td{
                font-family:$ibmplexRegular;
                font-size:14px;
                color:$color_000;
                padding: 10px;
                .carried-over-fund{
                  width: 14px;
                  position: relative;
                  top: -1px;
                  left: 8px;
                }
                .profile-loss-icon{
                  width: 14px;
                  position: relative;
                  top: -1px;
                  left: 4px;
                }
              }
            }
          }
          .shop-visit-block{
            .utilization-block{
              margin-bottom:26px;
              padding-left: 20px;
              label{
                margin-bottom:4px;
                font-family:$ibmplexRegular;
                font-size:12px;
                color:#BD10E0;
              }
              ul{
                li{
                  margin-right: 40px;
                  h6{
                    font-family:$ibmplexRegular;
                    font-size: 14px;
                    color: #000;
                    line-height: 14px;
                    &.changed-value{
                      color: #2a9ef5;
                    }
                  }

                  span.label{
                    font-family:$ibmplexRegular;
                    font-size:12px;
                    color:#a0a0a0;
                    display: block;
                    margin-bottom:3px;
                  }
                  input[type="text"]{
                    width: 110px !important;
                  }
                }
              }
            }
            table{
              // margin: 0 10px;

              th{
                font-size: 13px;
                background: #fafafa;

              }
              tr{
                &:first-child{
                  td{
                    padding-top:24px;
                  }
                }
                td{
                  .shop-visit-icon{
                    margin-right:10px;
                  }
                  .event-date-col{
                    text-align: left !important;
                  }
                  >div{
                    display: flex;
                    align-items: start;
                  }
                  &:first-child{
                    font-family:$ibmplexSemiBold;
                  }
                  ul{
                    li{
                      font-size: 14px;
                      line-height: 20px;
                      margin-bottom:10px;
                      span{
                        font-size: 14px;
                      }
                      img{
                        margin-left:5px;
                      }
                    }
                  }
                }
              }
            }
          }
          .engine-block{
            padding: 20px 0;
            .review-wrapper-block{
              margin-left:20px;
            }

            h5{
              background: transparent;
              padding:0 0 8px 15px;
              font-family:$ibmplexRegular;
              font-size:18px;
              color:#083c7b;
              margin: 8px 0 10px 0;
            }
            .tab-content{
              padding: 20px 0 0 0;
            }
          }
          .other-options{
            margin: 20px 15px;
            li{
              margin-right: 20px;
              label{
                input[type="radio"]{
                  margin-right: 8px
                }
              }
            }
          }
          .finance-graph-blk{
            margin-top: 60px;
            margin-bottom: 20px;
            .highcharts-container{
              width:100% !important;
              height:100% !important;
            }
          }
        }
      }
    }
  }
  .wing-analysis-block{
    background:#f0f2f3;
    border:1px solid #c6cddd;
    border-radius:3px;
    h5{
      font-family:$ibmplexRegular;
      font-size:20px;
      color:#083c7b;
      background: $color_fff;
      padding:15px;
      cursor: pointer;
      img{
        float:right;
        width: 16px;
      }
    }
    .finance-graph-blk{
      margin-top: 20px;
      .highcharts-container{
        width:100% !important;
        height:100% !important;
      }
    }
    .detail-block{
      padding:0 15px;
      background: $color_fff;
      .card{
        border:0;
        .card-body{
          border:0;
          padding:0;
          .wing-param-block{
            background:#f0f2f3;
            border:1px solid #c6cddd;
            border-bottom:none;
            border-radius:3px;
            border-right:0;
            border-left:0;
            h6{
              font-family:$ibmplexRegular;
              font-size:12px;
              color:#a0a0a0;
              text-align:left;
            }
            .rangeslider{
              box-shadow: 0 0 0;
              height:8px;
              margin:10px 0 0;
              .rangeslider__fill{
                background-color: #2f8cff;
              }
              .rangeslider__handle{
                width:12px;
                height:12px;
                &::after {
                  content: ' ';
                  position: absolute;
                  width: 6px;
                  height: 6px;
                  top: 2px;
                  left: 2px;
                  border-radius: 50%;
                  background-color: #dadada;
                  box-shadow: 0 0 0
                }
              }
            }
            span, p{
            font-family:$ibmplexRegular;
            font-size:14px;
            color:#000;
            text-align:left;
          }
          }
        }
      }
    }
  }
}
.engine-de-rate-modal{
  min-height: 240px;
  h3{
    font-size: 20px;
    margin-bottom: 20px;
    font-family:$ibmplexRegular;
    .back-icon{
      padding-right: 5px;
      cursor: pointer;
      width: 20px;
    }
    span{
      padding-left: 10px;
      font-size: 14px;
      color: #2f8cff;
      cursor: pointer;
      img{
        width: 20px;
        padding-right: 5px;
        top: 2px;
      }
    }
  }
  .cancel-derate-modal{
    font-family:$ibmplexRegular;
    font-size: 12px;
    padding: 0 20px;
    cursor: pointer;
  }
  .form-group{
    input[type="text"]{
      display: block;
      width: 100%;
      border: 1px solid #ccc;
    }
  }
  .loi-row{
    margin-bottom: 18px;
    table{
      width: 100%;
      border: 1px solid #e1e1e1;
      tr{
        td{
          border-right: 1px solid #e1e1e1;
          text-align: center;
          padding: 10px 0;
          &.base-ratio{
            background: #b0ccea;
          }
          &.selected{
            background: #4A90E2;
            color: #ffffff;
          }
        }
      }
    }
  }
  .derate-matrix-blk{
    button{
      margin-bottom: 20px;
      margin-right: 15px;
    }
    .cancel-derate-modal{
      display: inline-block;
      margin: 18px 0;

    }
    table{
      width: 100%;
      border: 1px solid #6794c8;
      thead{
        th{
          background: #afcbe9;
          padding: 10px;
          border: 1px solid #6794c8;
          font-family:$ibmplexRegular;
          font-size:14px;
        }
      }
      tbody{
        td{
          padding: 10px;
          border: 1px solid #6794c8;
          font-family:$ibmplexRegular;
          font-size:14px;
          &:first-child{
            background: #afcbe9;
            width: 140px;
          }
        }
      }
    }
  }

}

// xsmall devices
@media (max-width: 575.98px) {
  .mr-fund-rejection{
    margin:15px 0 15px;
 .utilization-block{
   ul{
     li{
      margin-bottom: 15px;

     }
   }
 }
  }
    .mobile-event-group{
      width:100%;
      overflow: auto;
      &::-webkit-scrollbar{
        display: none;
      }
      .grp-event-hd{
        width: 300%;
      }
      .grp-table-wrapper{
        width: 300%;
      }
    }

}

// Large devices (desktops, 992px and up)

@media (min-width: 992px) and (max-width: 1199.98px) {
  .mr-accrual-link{
    padding-right: 15px;

  }
  .mr-fund-rejection{
    margin:30px 15px 15px;
    .utilization-block{
      ul{
        li{
        margin-bottom: 15px;

        }
      }
    }
  }
  .wing-analysis-block{
    margin:0 15px;
    padding:15px 0;

  }
}

// Extra large devices (large desktops, 1200px and up)

@media (min-width: 1200px) {
  .mr-accrual-link{
    padding-right: 15px;

  }
  .mr-fund-rejection{
      margin:30px 15px 15px;
    .utilization-block{
      ul{
        li{
        margin-bottom: 15px;

        }
      }
    }
  }
  .wing-analysis-block{
    margin:0 15px;
    padding:30px 0;

  }
  .shop-visit-block{

  }
}
