@import '../setting';
@media (min-width: 992px) and (max-width: 1199.98px) {
  body{
    .MessageWrapper{
      width: 420px;
    }
    .apha{
      font-size: 36px
    }
    .hidden-lg{
      display:none;
    }
  }
}
@media (min-width: 1200px) {
  body{
    .MessageWrapper{
      width: 420px;
    }
    .apha{
      font-size: 36px
    }
    .hidden-lg{
      display:none;
    }
  }
}
