@import '../../../../assets/styles/setting';
.as-of-date{
  color:#69748c !important;
  font-size:11px !important;
  display:block;
  margin-bottom: 8px;
}
.contract-db-wrapper{
  margin: 0 15px 15px 60px;
  >div{    
    .db-left-bar{
      // padding-right:0;
      margin-bottom: 10px;
    }
  }
  h5{
    font-family:$ibmplexRegular;
    color:$color_000;
    &.contract-timeline{
      color:#f0a4ff;
      margin-bottom:30px;
    }
    &.avergare-monthly-heading{
      color: #bd10e0;
      font-size:16px;
    }
  }
  h6{
    font-family:$ibmplexRegular;
    font-size:12px;
    color:#8c9ba5;
    &.in-blue-bg{
      color:#f0a4ff;
    }
    &.airframe-text{
      color:$color_fff;
    }
    &.in-black{
      color:$color_000;
    }
  }
  p{
    font-family:$ibmplexRegular;
    font-size:14px;
    line-height:20px;
    color:#4a4a4a;
    &.in-blue-bg{
      color:$color_fff;
    }
    &.in-pink{
      color:#f0a4ff;
    }
  }
  .cashflow-top-bar{
    padding:15px 15px 10px;
    background:#f5f7fa;
    border-bottom:1px solid #c6cddd;
    border-radius:3px 3px 0 0;

    &.invoice-summary{
      padding-bottom:30px !important;
    }
  }
  .db-cashflow{
    border:1px solid #c6cddd;
    margin-bottom: 0px;
    .cashflow-blk-list{
      height:340px;
      overflow: auto;
      background: #ffffff;
      &::-webkit-scrollbar{
        display: none;
      }
      .last-month-title{
        font-size: 12px;
        margin: 10px 0 0 15px;
        color:#69748c;
      }
    }
    .cashflow-top-bar{
      h5{
        margin-bottom: 16px;
        color: #bd10e0;
        a{
          float: right;
          font-size: 12px;
          color: #3f51b5;
        }
      }
      span{
        font-family:$ibmplexRegular;
        color:$color_000;
        display:block;
        font-size: 16px;
      }
      p{
        font-family:$ibmplexRegular;
        color:#bd10e0;
        font-size: 13px;
        color: #636363;
        margin-bottom:5px;
      }
    }
    .cashflow-bottom-bar{
      position: relative;
      padding:15px;
      background:#ffffff;
      border-bottom-left-radius:3px;
      border-bottom-right-radius:3px;
      .cashflow-track-bar{
        background: #5596e6;
        top: 25px;
        bottom: 0;
        width: 1px;
        position: absolute;
      }
      .blue-dot{
        background:#5596e6;
        width:8px;
        height:8px;
        border-radius:50%;
        display: inline-block;
        position: absolute;
        left: -23.5px;
        top: 8.5px;
      }
      ul{
        margin-left:20px;
        li{
          position: relative;
          border-bottom:1px dashed #f0f2f3;
          padding-bottom:15px;
          margin-bottom:15px;
          &:last-child{
            border-bottom:0;
            margin-bottom:0;
          }
          span{
            &.date-text{
              font-family:$ibmplexRegular;
              font-size:14px;
              color:#000000;
              text-align:left;
            }
            &.pull-right{
              font-family:$ibmplexRegular;
              font-size:14px;
              color:$color_000;
              float:right;
            }
            &.pull-left{
              font-family:$ibmplexRegular;
              font-size:14px;
              color:$color_000;

            }
          }
          p{
            font-family:$ibmplexRegular;
            font-size:12px;
            color:#8b8d91;
          }
        }
      }
    }
  }
  .db-contract-timeline{
    border:1px solid #c6cddd;
    border-radius:3px;
    padding:15px;
    margin-bottom:15px;
    background-image:linear-gradient(-90deg, #6542c1 0%, #355bca 100%);
    border:1px solid #c6cddd;
    border-radius:3px;
    .status-timeline{
      background: rgba(255,255,255,0.3);
      margin-bottom:15px;
      height:4px;
      width:100%;
      position: relative;
      border-radius: 3px;
      span{
        background-image:linear-gradient(-90deg, #1DFFC3 0%, #5596E6 100%);
        height:4px;
        display: inline-block;
        position: absolute;
        border-top-left-radius:3px;
        border-bottom-left-radius:3px;
      }
    }
  }
  .db-upcoming-event{
    border:1px solid #c6cddd;
    background: $color_fff;
    background:#ffffff;
    margin-bottom:15px;
    .cashflow-bottom-bar{
      margin: 0;
    }
  }
  .header-grey-bg{
    background:#f5f7fa;
    border-radius:3px 3px 0 0;
    padding:14px;
  }
  .db-invoice{
    background:#fff;
    border:1px solid #c6cddd;
    border-radius:3px 3px 0 0;
    margin-bottom:15px;
    height:100%;
    tr:hover{
      background:#fafafa;
    }
    .db-invoice-heading{
      h5{
        margin-bottom: 15px;
        color: #bd10e0;
        a{
          float: right;
          font-size: 12px;
          color:#3f51b5;
        }
      }
    }
    .db-invoice-subHeading{
      text-align: right;
      border-bottom:1px solid #c6cddd;
      >div{
        justify-content: flex-end;
        >div{
          margin-left:20px;
        }
      }
    }
    .db-invoice-table-block{
      padding:0 10px 0px;
      height: 327px;
      background: #ffffff;
      overflow: auto;
      &::-webkit-scrollbar{
        display: none;
      }
      .last-month-title{
        font-size:12px;
        padding:10px 0 0 10px;
        color:#69748c;
      }
    }
    table{
      background: $color_fff;
      width:100%;
      thead{
        tr{
          border-bottom:1px dashed #f0f2f3;
          th{
            font-family:$ibmplexRegular;
            font-size:12px;
            color:$color_000;
            padding:10px;
          }
        }
      }
      tbody{
        tr{
          border-bottom:1px dashed #f0f2f3;
          cursor: pointer !important;
          &.in-due{
            .pretty-number-override-css {
              color: rgb(255, 0, 0) ;
            }
            &.not-due{
              color:#4a4a4a;
            }
            .over-due-text{
              color: rgb(255, 0, 0);
              font-size: 12px;
              font-family: $ibmplexSemiBold;
            }

          }
          td{
            padding:10px;
            font-family:$ibmplexRegular;
            color:#4a4a4a;
            font-size:14px;
            color:#4a4a4a;
            line-height:20px;
            .dropdown{
              button{
                font-size: 12px;
                padding: 0px 4px;
                background: #8c9ba5;
                border: 1px solid #6f818e;
                border-radius: 3px;
              }
            }
            .invoice-status{
              padding: 3px 3px;
              border-radius: 3px;
              width: 100px;
              display: inline-block;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .slick-track{
    float: left !important;
  }
  .airframe-cards{
    margin: 0px 0 15px 75px;

    .airframe-single-card{
      padding-right:0;
      h5{
        color:$color_fff;
        margin-bottom: 15px;
        font-size: 16px;
      }
      img{
        margin:0 auto 15px;
      }
      >div{
        padding: 15px;
        background:linear-gradient(90deg, rgb(44, 69, 193), rgba(150, 95, 222, 0.93));;
        border:1px solid #c6cddd;
        border-radius:3px;
        text-align: center;
      }
      &:last-child{
        padding-right: 15px;
      }
      .airframe-left-bar{
        border-right:1px solid rgba(255,255,255,0.11);
      }
      .airframe-left-bar, .airframe-right-bar{
        h6{
          margin-bottom: 0;
        }
        p{
          font-size: 20px;
          margin-top: 10px;
        }
      }
    }
  }
  .flagged-condition{
    .cashflow-top-bar{
      overflow: hidden;
      position: relative;
      .para{
        color:#bd10e0;
      }
      span{
        right: 15px;
        top: 10px;
        color: #2f8cff;
        cursor: pointer;

      }
    }
    .flagged-condition-wrapper{
      padding: 5px 10px;
      table{
        thead{
          border-bottom:1px solid #d7d7d7;
          th{
            font-size:14px;
            font-family: $ibmplexSemiBold;
            padding: 10px 5px;
          }
        }
        tbody{
          tr{
            border-bottom: 1px solid #f1f1f1;
            &:last-child{
              border-bottom: none;
            }
            td{
              padding: 10px 5px;
              ul{
                list-style: none;
                li{
                  font-size:13px;
                }
              }
            }
          }
        }
      }
      .return-cond-flag{
        border-bottom: 1px dashed #f1f1f1;
        padding-bottom: 15px;
        margin-bottom: 15px;
        &:last-child{
          border-bottom: none;
          padding-bottom: 0px;
          margin-bottom: 0px;
        }
      }
    }
  }
  .no-data{
    justify-content: center;
    background: $color_fff;
    width: 100%;
  }
  .invoice-cards{
    margin: 0 0 30px 0;
    .padding-right-0{
      margin-bottom: 20px;
    }
  .invoice-inner-block{
    background:linear-gradient(90deg, rgb(44, 69, 193), rgba(150, 95, 222, 0.93));
    border-radius: 1px;
    padding:15px;
    height:100%;
  
    h5{
      font-family:$ibmplexSemiBold;
      font-size:16px;
      color:#bfe6ff;
      line-height:22px;
      margin-bottom:11px;
      &.invoice-space-block{
        margin-bottom: 30px;
      }
    }
    h6{
      opacity:0.7;
      font-family:$ibmplexRegular;
      font-size:12px;
      color:$color_fff;
      margin-bottom:5px;
    }
    p{
      font-family:$ibmplexRegular;
      font-size:14px;
      color:$color_fff;
      line-height:22px;
      margin-bottom:15px;
      &.space-block{
        margin-bottom: 6px;
      }
      .as-of-date{
        color: #ffffff !important;
      }
    }
  
    .progress{
      height: 3px !important;
      background: #ff0000;
      .progress-bar{
         background: #2fc92f;
  
      }
    }
    .green{
      .progress{
        background: #2fc92f;
  
      }
    }
    }
  }
}
@keyframes cd-enter-right {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes cd-enter-left {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
// carousal prev and next
.slick-next{
  z-index: 9;
  right:11px !important;
  background: rgba(0,0,0,0.5);
  border-radius: 3px;
}
.slick-prev{
  z-index: 9;
  left:-5px !important;
  background: rgba(0,0,0,0.5);
  border-radius: 3px;
}

// timeline scss
.timeline-block{
  background:linear-gradient(90deg, rgb(44, 69, 193), rgba(150, 95, 222, 0.93));
  border-radius:3px;
  padding:15px;
  .timeline-heading-block{
    position:relative;
    .nav-btn{
      position: absolute;
      right: 0;
      top: 0;
      img{
        width: 26px;
        cursor: pointer;
        padding: 0 2px;
      }
    }
  }
  .timeline-inner-block{
    overflow: hidden;
    height: 355px;
    position: relative;
    .timeline-bar{
      flex-shrink: 0;
      background:$color_fff;
      height:1px;
      position: absolute;
      left: 0;
      -webkit-transition: all 1s; /* Safari */
      transition: all 1s;
      li{
        position: relative;
        width: 400px;
        margin: 0;
        span{
          &.dot{
            border:1px solid $color_fff;
            width:15px;
            height:15px;
            border-radius:50%;
            position: absolute;
            top: -25px;
            left: 0;
            background:#4f3fce;
          }
          &.line{
            height: 140px;
            border-right: 1px dashed #fff;
            display: inline-block;
            position: absolute;
            left: 6px;
          }
        }
        &:nth-child(even){
          top:0;
          span{
            &.line{
              bottom: 37px;
            }
          }
          .timeline-detail-block{
            bottom:45px;
          }
        }
        &:nth-child(odd){
          bottom:0;
          span{
            &.line{
              top:0;
            }
          }
          .timeline-detail-block{
            top:10px;
          }
        }
        &:first-child, &:last-child {
          span{
            &.dot{
              background:#f0a4ff;
            }
          }
        }
        .timeline-detail-block{
          position: absolute;
          left: 20px;
          h4{
            font-family:$ibmplexSemiBold;
            font-size:18px;
            color:$color_fff;
            margin-bottom:8px;
          }
          p{
            font-family:$ibmplexRegular;
            font-size:12px;
            color:$color_fff;
            margin-bottom:6px;
          }
          h5{
            font-family:$ibmplexRegular;
            font-size:14px;
            color:#f0a4ff;
            margin-bottom:8px;
          }
          img{
            margin-bottom:12px;
          }
          .view-more{
            font-family:$ibmplexSemiBold;
            font-size:12px;
            color:$color_fff;
            display:block;
          }
        }
      }
    }
  }
}
.return-condition-modal{
  max-width: 95%;
  .return-cond-modal-body{
    table.outer-table{
      width: 100%;
      thead{
        background: #fafafa;
        th{
          border:1px solid #d7d7d7;
          padding: 10px;
          font-size:15px;
          font-family: $ibmplexSemiBold;
          color:#6d9bb9;
        }
      }
      tbody{
        tr{
          .condition-component-name{
            color:#0a56a8;
          }
          border-bottom:1px solid #d7d7d7;
          td{
            padding:15px;
            border-right:1px solid #d7d7d7;
            border-left:1px solid #d7d7d7;
            .conditions-list{
              border-bottom: 1px solid #d7d7d7;
              padding-bottom: 20px;
              margin-bottom: 20px;
              &:last-child{
                border-bottom: none;
                padding-bottom: 0px;
                margin-bottom: 0px;
              }
              ul{
                list-style: none;
                li{
                  margin-bottom:10px;
                  font-size:13px;
                  span{
                    color:#bd10e0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
// Extra large devices (large desktops, 1200px and up)

@media (min-width: 1200px) {
  .contract-db-wrapper{
    h5{

      &.avergare-monthly-heading{

        margin: 10px 0 10px 15px;
      }
    }
    .timeline-block{
      margin:0 0px 15px 0px !important;

    }
    .db-invoice-table-block{

      overflow: hidden;
    }
    .flagged-condition{
      margin:0 0px 15px 0px;
      .cashflow-top-bar{
        span{
          position: absolute;
          color: #2f8cff;
          cursor: pointer;
          img{
            width: 30px;
            &.info-icon{
              width: 16px;
              position: relative;
              top: -2px;
            }
          }
        }
      }
    }


  }
}

// Large devices (desktops, 992px and up)

@media (min-width: 992px) and (max-width: 1199.98px) {
  .contract-db-wrapper{
    h5{
      &.avergare-monthly-heading{
        margin: 10px 0 10px 15px;
      }
    }
    .timeline-block{
      margin:0 15px 15px !important;

    }
    .db-invoice-table-block{

      overflow: hidden;
    }
    .flagged-condition{
      margin:0 15px 15px;
      .cashflow-top-bar{
        span{
          position: absolute;
          color: #2f8cff;
          cursor: pointer;
          img{
            width: 30px;
          }
        }
      }
    }
  }
}


// xsmall devices
@media (max-width: 575.98px) {
  .return-cond-modal-body{
    overflow: auto;
    &::-webkit-scrollbar{
      display: none;
    }
  }

  .contract-db-wrapper{
    >div{
      margin:0;
      .db-left-bar{
        padding-left: 0;
        margin-bottom:15px;
      }
      .invoice-summary-spacing{
        padding:0;
        margin-bottom: 15px;
      }
    }
    h5{
      &.avergare-monthly-heading{
        margin-bottom:8px;
        .as-of-date{
          margin-top:4px;
          display:block;
        }
      }
    }
    .slick-slider{
      margin-left: 0;
      margin-right: -15px;
      .slick-list{
        width: 100%;
      }
    }
    .timeline-block{
      margin:0 0 15px ;
    }
    .db-invoice-table-block{

      overflow: auto;
      &::-webkit-scrollbar{
        display: none;
      }
    }

    .flagged-condition{
      .cashflow-top-bar{
        span{
          margin-top: 8px;
          display: block;
          font-size: 12px;
          img{
            width: 20px;
          }
        }
      }
    }
  }
}
