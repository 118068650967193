@import '../../../../../assets/styles/setting';
body{
  font-family: $ibmplexRegular;
  background:#F0F2F3;
  .use-cdb-algo{
    border-left: 1px solid #a0a0a0;
    padding-left: 30px;
    margin-left: 10px;
    display: inline-block !important;
  }
  .genFinanceRpt-blk{
    margin-left:255px !important;
    .pretty-number-override-css{
      color:#000000!important;
      font-size:14px !important;
    }
    .submit-block{
      margin: 15px 26px;
      .generate-mr-data{
        padding:6px 26px;
        font-size:12px;
        color:#ffffff;
      }
    }
    .fixed-component-bar{
      background: #ffffff;
      border: 1px solid #DFE3E6;
      margin-bottom: 20px;
      ul{
        width: 100%;
        li{
          text-align: center;
          width: 25%;
          margin: 0;
          padding: 0;
          border-right: 1px solid #DFE3E6;
          &:last-child{
            border-right: none;
          }
          a{
            padding: 15px 0;
            display: inline-block;
            width: 100%;
            border-bottom: 2px solid #ffffff;
            color: #8C9BA5;
            &.current-menu{
              border-bottom: 2px solid #3f51b5;
              color:#3f51b5;
            }
          }
        }
      }
    }
  }
// airframeinfCard and EngineInfCard scss
.info-card{
  margin: 0 15px;
  .engine-info-card{
    margin-left:10px;
    background-color:$color_fff;
    border:1px solid #c6cddd;
    box-shadow:0 2px 4px 0 rgba(0,0,0,0.10);
    border-radius:3px;
    margin-bottom:16px;
    padding: 20px 20px 20px 20px;
    h6{
      font-size:16px;
      color:#9439e4;
      margin-bottom:25px;
      font-family: $ibmplexRegular;
    }
    .outer-wrap, .aircraft-outer-wrap{
      .inner-wrap{
        position: relative;
        margin-bottom: 5px;
          label{
            display: block;
            font-size: 13px;
            color: #a0a0a0;
            margin-bottom: 2px;
            line-height:12px;
          }
          select{
            border-bottom: 1px solid #838383;
            background: transparent;
            width: 90%;
            font-family:$ibmplexRegular;
            font-size:14px;
            color:#8c9ba5;
          }
          span{
            // font-size: 14px;
            // color:#8b8d91;
            // font-family: $ibmplexRegular;
            // display: inline-block;
            // margin-left: 6px;
          }
          input[type="text"]{
            border-bottom: 1px solid #838383;
            width: 90%;
            font-family:$ibmplexRegular;
            font-size:14px;
            background: transparent;
            &:focus{
              border-bottom:1px solid #3f51b5;
            }
          }
          &.adjust-link-block{
            position: relative;
            .custom-select-block{
              width:90%;
            }
            .custom-region-sel{
              position: absolute;
              left:8px;
              top:80px;
              cursor: pointer;
              font-size: 10px;
              color:#000000;
            }
            .adjust-link{
              position: absolute;
              left:14px;
              top:60px;
              cursor: pointer;
              font-size: 10px;
              color:#3f51b5;
            }
          }
          .engine-no-block{
            li{
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
  //region slider change scss
  .assign-sidebar-block{
    overflow: auto;
    &::-webkit-scrollbar{
      display: none;
    }
    padding: 20px 40px;
    width:400px;
    position: fixed;
    right: 0;
    top: 0px;
    bottom:0;
    background:#ffffff;
    box-shadow:0 2px 4px 0 rgba(0,0,0,0.20);
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    z-index: 9999;
    .rangeslider__labels{
      display: none;
    }
    .rangeslider__handle-tooltip{
      display: inline-block;
      width: 100px;
    }
    &.hide-regionbar{
      right:-400px;
    }
    &.show-regionbar{
      right:0;
      strong{
        float: right;
        background: #d3d3d3;
        border-radius: 50%;
        padding: 5px 10px;
        font-size: 12px;
        cursor: pointer;
      }
    }
    .custom-select-block{
      margin-bottom:30px;
      .Select-placeholder{
        font-size:20px;
        color:$color_000;
      }
    }
    ul{
      li{
        margin-bottom: 30px;
        h6{
          font-family:$ibmplexRegular;
          color:$color_000;
          margin-bottom:15px;
          &.non-range-selector-block{
            margin-bottom:6px;
          }
        }
        .rangeslider-horizontal{
          height:9px;
          margin: 0;
          box-shadow: 0 0 0;
        }
        .rangeslider-horizontal{
          .rangeslider__handle{
            width: 18px;
            height: 18px;
            background:#ffffff;
            border:1px solid #bcc4d4;
            box-shadow:0 2px 4px 0 rgba(0,0,0,0.20);
          }
          .rangeslider__handle:after{
            top: 3px;
            left: 3px;
            width:10px;
            height:10px;
            box-shadow: 0 0 0;
            background:#b1bfd0;
          }
          .rangeslider__fill{
            background:#2f8cff;
          }
        }
        label{
          margin-right:20px;
          font-size:14px;
          color:#8b8d91;
          line-height:12px;
          input[type="radio"]{
            margin-right:8px;
          }
        }
        &.submit-block{
          margin-top:20px;
          input{
            padding:5px 25px;
            margin-right: 15px;
          }
          a{
            font-size:12px;
            color:#000000;
          }
        }
      }
    }
  }
  // review module wrapper scss
  .review-module-wrapper{
    margin:0 15px 0 30px;
    .common-mr-card{
      background:$color_fff;
      border:1px solid #c6cddd;
      box-shadow:0 2px 4px 0 rgba(0,0,0,0.10);
      border-radius:3px;
      margin-bottom:15px;
      h6.para{
        font-family:$ibmplexRegular;
        font-size:20px;
        color:#083c7b;
        margin-bottom:0;
        padding: 15px;
        cursor:pointer;
        transition: all 0.5s ease;
        &:hover{
          color:#2f8cff;
        }
        img{
          float: right;
          width: 16px;
        }
      }
    .airframe-mr-detail{
        .card{
          border:0 ;
          .card-body{
            padding: 0;
            table{
              thead{
                tr{
                  th{
                    padding:5px 15px;
                    font-family:$ibmplexRegular;
                    color:#8c9ba5;
                    &.th-highlight{
                      color:#bd10e0;
                    }
                  }
                }
              }
              tbody{
                tr{
                  &:nth-child(odd){
                    background:#f1f6f7;
                  }
                  &:nth-child(even){
                    background:#f8fafa;
                  }
                  td{
                    padding:15px;
                    font-family:$ibmplexRegular;
                    color:#8c9ba5;
                    text-align:left;
                    span.field-container{
                      position: relative;
                      display: inline-block;
                      .prefix{}
                      .suffix{
                        position: relative;
                        right: 26px;
                      }
                      img{
                        position: absolute;
                        width: 20px;
                        right: 0;
                        top: 7px;
                      }
                    }
                    input{
                      font-size: 12px;
                      font-family:$ibmplexRegular;
                      background: transparent;
                      padding-left: 3px;
                      font-size: 14px;
                      &.edit-field{
                        border-bottom: 2px solid #e1e1e1;
                      }
                    }
                    hr{
                      border:1px dashed #2f8cff;
                      width:14px;
                      margin:0;
                    }
                    &.error-cls{
                      .edit-field{
                        border-bottom: 2px solid #ff0000;
                      }
                    }
                  }
                }
              }
            }
        }
      }
    }
    .lg-mr-detail{
      .card{
        border:0;
        .card-body{
          padding:15px;
        }
        .lg-entry-block{
          margin-bottom:20px;
        }
        label{
          font-size: 12px;
          font-family:$ibmplexRegular;
          color:#2f8cff;
          display: block;
          margin-bottom: 2px;
          line-height: 8px;
        }
        input{
          font-family:$ibmplexRegular;
          font-size:14px;
          border-bottom:1px solid #979797;
          &:focus{
            border-bottom:1px solid #2f8cff;
          }
        }
        .lg-radio-block{
          padding: 10px 0;
            li{
              margin-right:20px;
              label{
                input{
                  margin-right:6px;
                }
                span{
                  font-family:$ibmplexRegular;
                  font-size:12px;
                  color:#000000;
                  position: relative;
                  top: -1px;
                }
              }
            }
          }
          .lg-side-block{
            background:#f5f7fa;
            border:1px solid #e1e5ee;
            border-radius:3px;
            padding:15px;
            width:183px;
            margin: 0 0 0  auto;
            li{
              margin-bottom:24px;
              h6{
                padding:0;
                font-family:$ibmplexRegular;
                font-size:12px;
                color:#8c9ba5;
              }
              p{
                font-family:$ibmplexRegular;
                color:#8b8d91;
                line-height:20px;
                &.marginal-content{
                  color:#bd10e0;
                }
              }
              &:last-child{
                margin-bottom:0;
              }
            }
          }
        }
      }
      .engine-mr-detail{

        .card{
          border: 0;
            .card-body{
              padding: 0;
              .engine-detail-block{
                padding: 0 ;
                margin-bottom:10px;
                display: flex;
                .adjust-link{
                  left: 14px;
                  top: 60px;
                  cursor: pointer;
                  font-size: 10px;
                  color: #2f8cff;
                }
                &:before, &:after{
                  width: 0;
                }
                  li{
                    margin-left:15px;
                    margin-right:30px;
                    span{
                      font-family:$ibmplexRegular;
                      font-size:12px;
                      color: #a0a0a0;
                    }
                    p{
                      font-family:$ibmplexRegular;
                      font-size:14px;
                      color:#000000;
                      margin-bottom:0;
                      line-height:20px;
                    }
                    &:last-child{
                      width: 120px;
                      .Select-menu-outer{
                        z-index: 100 !important;
                      }
                    }
                  }

                }
                .nav-tabs{
                  .nav-item{
                    .nav-link{
                      font-family:$ibmplexRegular;
                      font-size: 15px;
                      color: #8C9BA5;
                      border: none;
                      border-bottom: 1px solid #dee2e6;
                      cursor: pointer;
                      &.active{
                        font-family:$ibmplexSemiBold;
                        color: #2f8cff;
                        border-bottom: 1px solid #2f8cff;
                      }
                      &:hover{
                        color: #2f8cff;
                        border-bottom: 1px solid #2f8cff;
                      }
                    }
                  }
                }
                .tab-content{
                  background-image: linear-gradient(0deg,transparent 72%,rgba(0, 0, 0, 0.04));
                  .engine-usage-form{
                    position: relative;
                    padding-top: 50px;
                    background: #ffffff;
                    .engine-tab-block{
                      position: absolute;
                      left: 0;
                      top: 0px;
                      background: #F5F7FA;
                      z-index: 8;
                      width: 100%;
                      padding: 0 15px;
                      h6.engine-extra-data{
                        font-family:$ibmplexRegular;
                        font-size: 16px;
                        color: #3D70B2;
                        background: #F5F7FA;
                        padding: 15px 0;
                        cursor: pointer;
                        img{
                          width: 20px;
                          float: right;
                        }
                      }
                      .fund-block{
                        margin-bottom: 30px;
                      }
                      button.update-usage-info{
                        display: inline-block;
                        margin-top: 20px;
                        padding: 6px 30px;
                      }
                      label{
                        font-family:$ibmplexRegular;
                        font-size:12px;
                        color:#2f8cff;
                        display:block;
                        margin-bottom:2px;
                        line-height: 12px;
                        &.match-llp-block{
                          cursor: pointer;
                          font-family:$ibmplexRegular;
                          font-size:14px;
                          color:#8b8d91;
                          margin-top:10px;
                          display: inline-block;
                          line-height: 12px;
                          margin-bottom: 20px;
                          input{
                            margin-right:6px;
                          }
                        }
                      }
                      .edit-llp{
                        font-family:$ibmplexRegular;
                        font-size:12px;
                        color:#2f8cff;
                        margin-left:10px;
                        cursor: pointer;
                      }
                      input{
                        border-bottom:1px solid #979797;
                        font-family: $ibmplexRegular;
                        font-size: 14px;
                        line-height: 20px;
                        background: transparent;
                        &:focus{
                          border-bottom:1px solid #2f8cff;
                        }
                      }
                    }
                  }
                  .utli-heading{
                    padding:0 0 12px 0;
                    font-size: 12px;
                    font-family:$ibmplexRegular;
                    color:#000000;
                  }
                  .utilisation-block{
                    margin-bottom:30px;
                    label{
                      font-family:$ibmplexRegular;
                      font-size:12px;
                      display:block;
                      color:#2f8cff;
                      margin-bottom:2px;
                      line-height:12px;
                    }
                    input{
                      font-family:$ibmplexRegular;
                      font-size:14px;
                      line-height:20px;
                      border-bottom:1px solid #828282;
                      background: transparent;
                      &:focus{
                        border-bottom:1px solid #2f8cff;
                      }
                    }
                  }
                  .add-time-and-cycle{
                    label.match-llp-block{
                      margin-bottom: 15px;
                    }
                    .add-lsv{
                      padding-left: 16px;
                      ul{
                        margin-bottom: 16px;
                        li{
                          span{
                            padding-right: 16px;
                            padding-left: 6px;
                            font-family: $ibmplexRegular;
                            font-size: 12px;
                            color: #000;
                            position: relative;
                          }
                        }
                      }
                    }
                  }
                  .llp-block{
                    padding: 15px 30px 15px 15px;
                    .col-spacing-wrap{
                      padding-right:0;
                      .wrapper-block{
                        position: relative;
                        background:#f5f7fa;
                        border:1px solid #c6cddd;
                        border-radius:3px;
                        h6{
                          font-family:$ibmplexRegular;
                          font-size: 16px;
                          color: #BD10E0
                        }
                        span.text-label,label{
                           font-family:$ibmplexRegular;
                           font-size:12px;
                           color:#a0a0a0;
                         }
                         p.para-ui{
                           font-family:$ibmplexRegular;
                           font-size:14px;
                           color:#000000;
                           line-height:20px;
                         }
                         .run-type-blk, .workscope-blk, .restoration-blk, .llp-replacement-blk, .total-amt-blk{
                           padding: 15px;
                           border-bottom: 1px solid #D9D9D9;
                           background: #ffffff;
                           &.workscope-blk{
                            padding:20px 15px 15px;
                             position: relative;
                             .restricted-llp{
                               position: absolute;
                               top:0;
                               left:0;
                               right:0;
                               margin-left: 5px;
                               font-size: 12px;
                               color: #806420;
                               font-family:$ibmplexSemiBold;
                               background-color: #fffbd8;
                               border-bottom: 1px solid #d0bb7d;
                               border-top: 1px solid #d0bb7d;
                               margin: 0;
                               padding: 1px;
                               text-align: center;
                             }
                             img{
                               width: 18px;
                               margin-left: 6px;
                               cursor: pointer;
                             }
                             label{
                               font-size: 14px;
                               color: #000000;
                               input[type="checbox"]{
                                 margin-right: 6px;
                                 display: inline-block;
                               }
                             }
                           }
                           &.total-amt-blk{
                             span{
                               color: #BD10E0;
                             }
                           }
                           .restoration-extra-info, .llp-extra-info{
                              -moz-transition: height 1s ease;
                              -webkit-transition: height 1s ease;
                              -o-transition: height 1s ease;
                              transition: height 1s ease;
                           }
                           &.run-type-blk, &.total-amt-blk{
                             background: #F0F2F3;
                           }
                           .inner-row{
                             margin-bottom: 12px;
                           }
                           .show-more{
                             font-family:$ibmplexRegular;
                             font-size:12px;
                             color: #2F8CFF;
                             cursor: pointer;
                           }
                         }
                      }
                    }
                  }

                .blended-mr-card{
                  padding:15px 0 0;
                  ul{
                    padding:20px;
                    margin-bottom:0;
                    background:#f5f7fa;
                    border:1px solid #c6cddd;
                    border-radius:3px;
                    justify-content: center;
                    li{
                      margin:0 30px;
                      text-align: center;
                      span{
                        font-family:$ibmplexRegular;
                        color:#a0a0a0;
                        &.hightlight-block{
                          color:#bd10e0;
                        }
                      }
                      p{
                        font-family:$ibmplexRegular;
                        color:$color_000;
                        line-height:20px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    }
    .llp-brekup-modal{
      .modal-content{
        border-radius: 0;
        .modal-header{
          display: block;
          padding:20px 15px;
          border:0;
          &.no-spacing{
            padding-bottom: 2px;
            h5{
              padding-bottom:0;
            }
          }
          h6{
            font-family:$ibmplexRegular;
            font-size:14px;
            color:#8b8d91;
            &.shop-visit-heading{
              font-family:$ibmplexRegular;
              font-size:12px;
              color:#2f8cff;
              margin-bottom: 3px;
            }
          }
          h5{
            font-family:$ibmplexRegular;
            font-size:16px;
            color:#2f8cff;
            text-align:left;
            &.replace-block{
              color:#3d70b2;
            }
            &.cc-reminaing-heading{
              margin-bottom: 3px;
            }
          }
          .close{
            position: absolute;
            top: 14px;
            right: 15px;
          }
        }
        .modal-body{
          padding:0 15px 20px;
          overflow-x:hidden;
          overflow-y: auto;
          &.engine-llp{
            padding:0;
            table{
              margin:0 15px 15px;
              width:94%;
            }
          }
          table{
            width:100%;
            thead{
              background:#f5f7fa;
              tr{
                th{
                  padding:7px 7px;
                  font-family:$ibmplexSemiBold;
                  font-size:12px;
                  color:#000000;

                  &:last-child{
                    text-align: right;
                  }
                }
              }
            }
            tbody{
              tr{
                &:nth-child(odd){
                  background:$color_fff;
                }
                &:nth-child(even){
                  background:#f5f7fa;
                }
                td{
                  padding:7px 7px;
                  font-family:$ibmplexRegular;
                  color:$color_000;
                  .input-wrap{
                    display: inline-block;
                    width:36px;
                  }
                  input{
                    background: transparent;
                    height:auto;
                  }
                  label{
                    span{
                      display:inline-block;
                      margin-left: 10px;
                      width: 75%;
                    }

                  }

                  hr{
                    border:1px dashed #2f8cff;
                    width:14px;
                    margin:0;
                  }
                  &:last-child{
                    text-align: right;
                  }
                }
              }
            }
          }
          .select-llp-replace-total{
            span{
              float: right;
            }
          }
        }
        .modal-footer{
          background: #f0f2f3;
          display: block;
          padding:15px;
          border:none;
          p{
            font-family:$ibmplexRegular;
            font-size:12px;
            color:#000000;
            text-align:right;
            display: block;
          }
          span{
            font-family:$ibmplexSemiBold;
            font-size:14px;
            color:#000000;
            cursor: pointer;
            text-align: right;
            padding-right:3px;
          }
          button{
            font-family:$ibmplexRegular;
            background:#2f8cff;
            border:1px solid #2f8cff;
            border-radius:3px 2px 2px 3px;
            padding: 5px 40px;
            // display: block;
            cursor: pointer;
            color: #fff;
          }
        }
      }
      .select-llp-replace-btn{
        padding: 6px 16px;
        border-radius: 3px;
        cursor: pointer;
      }
      &.custom-llp-cycle{
        .input-wrap{
          width: 70px !important;
        }
        input[type="text"]{
          width: 70px !important;
          border-bottom: 2px solid #e1e1e1;
        }
      }
  }
