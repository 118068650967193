@import '../setting';

@media (max-width: 991px){
  body{
    overflow: auto !important;
    padding-bottom: 50px;
  }
  .MessageWrapper{
    width: 80%;
  }
  .apha{
    font-size: 26px
  }
  .hidden-xs{
    display: none;
  }
  .margin-bottom-xs{
    margin-bottom:15px;
  }
  .margin-bottom-8-xs{
    margin-bottom:8px;
  }
  .tracker-block{
    .add-filter-type{
      margin: 0;
    }
    .add-bulk-operations{

    }
  }
  .padding-left-xs-0{
    padding-left:0;
  }
  .margin-bottom-15-xs{
    margin-bottom:15px;

  }
  .overflow-auto-xs{
    overflow: auto;
    &::-webkit-scrollbar{
      display: none;
    }
  }
  .mobile-bread-crumb{
    text-align: right
  }
  .core-details-block{
    .add-filter-type{
      margin: 0 15px;
    }
  }

  body{
    .fleet-dashboard, .fleet-contracts-db, .fleet-records-db, .fleet-technical-db, .fleet-maintenance-db{
      margin: 0 0 0 60px;
      .title-block{
        margin-left: -15px;
      }
      .col1{
        width: 100% !important;
        padding: 0 !important;
        .widget-cards{
          margin-left: 0 !important;
          margin-right: 0 !important;
          &:nth-of-type(even){
            margin-left: 10px !important;
          }
        }
        .col-md-12{
          margin-left: 0 !important;
        }
      }
      .assets-row{
        margin-left: 0 !important;
        margin-right: 0 !important;
        display: block;
        .widget-cards{
          margin-bottom: 15px;
        }
        .marginLeft{
          margin-left: 0;
        }
      }
      .fleet-summary{
        margin-left: 0 !important;
        margin-right: 0px !important;
        >div{
          margin-bottom: 15px;
          padding: 0;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
      .subcomponent-detail-block{
        margin: 0;
        >div{
          margin-bottom: 15px;
          padding: 0;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
      .assetLocation{
        margin: 15px -15px;
      }
    }
    .fleet-contracts-db{
      margin-top: 65px;
      .db-back-arrow{
        left: 40px;
      }
      .col1{
        .widget-cards{
          margin-left: 0 !important;
          margin-right: 0 !important;
          // &:nth-of-type(even){
          //   margin-left: 5px !important;
          // }
        }
      }
      .msn-sw{
        .date-range-block{
          width: 240px;
        }
      }
      .contract-card-widget{
        .paddingRight{
          padding-right: 10px;
          padding-left: 10px;
          margin-bottom: 15px;
        }
      }
      .cashflow-summary-widget{
        >.row{
          margin-right: 0;
        }
        .padding-left-0{
          padding-right: 0;
          .msn-sw{
            height: 150px;
            .date-range-block{
              margin-bottom: 15px;
            }
            .select-contract-block{
              justify-content: flex-start;
              width: 100%;
              .Select{
                width: 100%;
              }
            }
          }
        }
      }
      .invoice-summary-widget{
        >.row{
          margin-right: 0;
        }
        .padding-left-0{
          padding-right: 0;
        }
        .table-responsive{
          table{
            tr{
              table-layout: auto;
            }
          }
        }
      }
      .utilization-widget-cn{
        .msn-sw{
          height: 150px;
          .date-range-block{
            margin-bottom: 15px;
          }
          .select-contract-block{
            justify-content: flex-start;
            width: 100%;
            .Select{
              width: 100%;
            }
          }
        }
        .graphical-tabs{
          height: auto;
          margin-bottom: 20px;
          .nav-tabs{
            padding-top: 20px;
            li{
              margin-bottom: 15px;
            }
          }
          .utils-row{
            .utils-ratio{
              margin-bottom: 15px;
            }
          }
        }
      }
    }
    .fleet-records-db, .fleet-technical-db, .fleet-maintenance-db{
      margin-top: 55px;
      .db-back-arrow{
        left: 40px;
      }
      .col1{
        .widget-cards{
          margin-left: 0 !important;
          margin-right: 0 !important;
          // &:nth-of-type(even){
          //   margin-left: 5px !important;
          // }
        }
      }
      table{
        tr{
          table-layout: auto;
        }
      }
      .file-status-card-size{
        .fleet-size-details{
          &:last-child{
            margin-bottom: 30px;
          }
        }
        .fleet-dash-progress{
          ul{
            li{
              width: 100%;
              margin-bottom: 15px;
            }
          }
        }
      }
      .file-status-card, .shared-content-cn{
        margin-left: 15px;
      }
      .shared-content-cn{
        margin-bottom: 30px;
      }
    }
    .fleet-technical-db{
      .projects-location{
        margin-top: 20px;
      }
    }
    .fleet-maintenance-db{
      .claim-list{
        >div{
          overflow-x: auto;
          margin: 0 -15px;
        }
      }
      .claim-tracker-cn .claim-stats{
        min-height: 300px;
      }
      .major-assembly-card .mj-assembly-card{
        margin-top: 20px;
      }
    }
    .technical-inspection-cn{
      margin-left: 60px;
      .technical-projects{
        .projects-tab{
          margin-top: 10px !important;
          .ui-tabs{
            li{
              a{
                padding: 8px 0;
              }
            }
          }
        }
        .table-ui-component{
          .table-sticky{
            tr{
              table-layout: auto;
              th,td{
                min-width: 100px;
                .project-info{
                  h4{
                    font-size: 12px; 
                  }
                }
                img{
                  width: 15px;
                  height: 15px;
                }
              }
            }
          }
          .add-bulk-operations{
            display: flex;
            display: -webkit-flex;
            align-items: center;
            flex-flow: wrap;
            -webkit-flex-flow: wrap;
            &:before,&:after{
              width: 0;
            }
            .aims-primary-btn{
              top: 0;
              padding: 5px 10px;
            }
          }
          .table-list-filter{
            display: none;
          }
        }
      }
      &.project-view{
        .technical-project-view{
          .view-header{
            .col-md-8{
              padding: 0;
              .project-edit{
                li{
                  width: 100% !important;
                  min-width: 100% !important;
                }
              }
            }
            .col-md-4{
              padding: 0;
              .lessee-info{
                float: none;
                li{
                  margin-top: 10px;
                  margin-left: 0;
                  margin-right: 30px;
                  .project-status, .projectform-status{
                    position: static;
                  }
                }
              }
            }
          }
          .project-title-add{
            .download-block{
              button{
                margin-left: 0;
                margin-top: 10px;
              }
            }
          }
          .project-view-asset{
            overflow-x: auto;
            table{
              tr{
                table-layout: auto;
                th,td{
                  min-width: 200px !important;
                }
              }
            }
            .card-body{
              padding: 0 !important;
            }
            .collapse{
              width: auto;
              display: inline-block;
            }
          }
        }
      }
      &.project-listing{
        .table-height{
          min-height: 200px;
        }
      }
    }
    .log-listing-block{
      .add-enquiry-block a{
        padding: 7px;
        margin: 10px 0;
      }
      .mobile-asset-list{
        overflow-x: auto;
        border: 1px solid #dddddd; 
        .logHeading{
          padding: 7px 0;
          white-space: nowrap;
          display: inline-block;
          border: none;
          >div{
            width: 150px;
            display: inline-block;
          }
        }
       
      }
      .logList{
        padding: 7px 0;
        white-space: nowrap;
        display: inline-block;
        border: none;
        >div{
          width: 150px;
          display: inline-block;
          vertical-align: top;
          ul{
            li{
              display: contents;
            }
          }
        }
      }
    }
    .asset-listing-wrap.fleet-list{
      padding: 0;
      padding-bottom: 15px;
      .fleet-list-wrapper-blk{
        overflow-x: auto;
        border: 1px solid #dfe3e6;
        .asset-list-filter-sort{
          .filter-block{
            white-space: nowrap;
            display: inline-table;
            overflow: hidden;
            border-left: none;
            border-right: none;
            >div{
              display: inline-block;
              width: 200px;
            }
          }
        }
        .asset-list{
          display: inline-block;
          white-space: nowrap;
          border-left: none;
          border-right: none;
          a{
            >div{
              white-space: nowrap;
              display: inline-table;
              overflow: hidden;
              >div{
                display: inline-block;
                width: 200px;
              }
            }
          }
          
        }
      }
      .generate-finance-heading{
        left: 65px !important;
        top: 50px !important;
        padding-top: 30px;
      }
      .asset-listing-body{
        .fleet-cashflow-data{
          ul{
            float: none;
            li{
              margin-left: 0;
              margin-top: 5px;
              margin-bottom: 5px;
              border: none;
              text-align: left;
              padding-left: 0;
              display: block;
            }
          }
        }
        .fleet-assets-wrapper{
          overflow-x: auto;
        }
        .asset-list-filter-sort{
          .filter-block{
            display: inline-block;
            white-space: nowrap;
            >div{
              display: inline-block;
              width: 200px;
            }
          }
        }
        .fleet-asset-list{
          overflow: unset !important;
          height: auto !important;
          margin-bottom: 70px;
          .asset-list{
            display: inline-block;
            white-space: nowrap;
            h4{
              font-size: 14px;
            }
            >div{
              display: inline-block;
              white-space: nowrap;
              >div{
                display: inline-block;
                width: 200px;
              }
            }
          }
        }
      }
      .portfolio-footer{
        .add-new{
          display: inline-block !important;
        }
      }
    }
    .asset-listing-wrap{
      .mobile-asset-list{
        .msn-block img{
          display: none;
        }
      }
    }
    .user-profile-blk{
      padding-top: 0;
      #user-search-form{
        float: none;
        width: 100%;
        margin-top: 0;
      }
    } 
    .application-edit-side-bar{
       .edit-bar-content-blk{ 
        .body-content{
          .sidebar-form{
            padding-bottom: 150px !important;
          }
        }
      }
    }
    .project-listing{
      .application-edit-side-bar{
        .edit-bar-content-blk{ 
          .edit-sidebar-footer{
           bottom: 0 !important;
         }
       }
     }
    }
    .assets-detail-section{
      margin-left: 60px;
      .aims-different-product-list{
        .platform-card{
          height: auto;
        }
      }
    }
    .billing-container.notification-container{
       .billing-content-wrapper{
          padding-left: 60px !important;
          .table-ui-component{
            .table-sticky{
              tr{
                table-layout: auto;
                th,td{
                  min-width: 100px;
                  .project-info{
                    h4{
                      font-size: 12px; 
                    }
                  }
                  img{
                    width: 15px;
                  }
                }
              }
            }
            .table-list-filter{
              padding: 0;
            }
          }
       }
    }
    .user-profile-blk{
      #user-search-form .btn{
        margin-bottom: 15px;
      }
      .users-list-wrapper{
        border-right: 1px solid #d3d3d3;
        overflow-x: auto;
        clear: both;
        margin-left: 60px;
        .users-list{
          margin-left: 0 !important;
          tr{
            width: auto !important;
            table-layout: auto;
            td{
              width: 300px !important;
            }
          }
        }
      }
    }
    .header-logo-search{
      margin-right: 0 !important;
      .cart-count{
        color: #fff;
        font-size: 8px;
        display: inline-block;
        position: relative;
        top: -10px;
        background: red;
        border-radius: 50%;
        padding-left: 3px;
        padding-right: 3px;
        margin-left: -10px;
      }
    }
    .transfer-modal{
      min-width: 300px !important;
      .modal-content{
         .modal-body{
           padding: 0;
            .transfer-modal-body{
              width: 100% !important;
              margin: 0;
            }
        }
     }
     .transfer-modal-footer{
        margin: 0;
        padding: 20px 0;
     }
    }
    .create-engine-form{
      .create-engine{
        padding: 0;
        overflow: hidden;
        .general-detail-block{
          padding: 15px 0 0;
          .row{
            width: 100% !important;
          }
        }
      }
    }
  }
  body{
    .mr-calculator-logs{
      .mr-calc-sidebar{
        opacity: 1;
        visibility: visible;
        height: 90px;
        z-index: 91;
        left: 60px !important;
        top: 40px !important;
        height: 48px;
        .mbl-cross{
          display: none;
        }
        .mr-calc-heading{
          padding-top: 15px;
          display: none;
          h3{
            padding-bottom: 0;
          }
        }
        ul{
          white-space: nowrap;
          overflow-x: auto;
          margin-top: 0;
          li{
            display: inline-block;
            a{
              padding: 15px 15px;
              &.active{
                text-decoration: underline;
              }
            }
            &:last-child{
              padding-right: 60px;
            }
          }
        }
      }
      .log-listing-block{
        padding-top: 55px;
      }
    }
    .genFinanceRpt-blk{
      margin-left: 45px !important;
      margin-top: 42px;
      .mr-calc-sidebar{
        opacity: 1;
        visibility: visible;
        height: 90px;
        z-index: 91;
        left: 60px !important;
        top: 40px !important;
        height: 48px;
        .mbl-cross{
          display: none;
        }
        .mr-calc-heading{
          padding-top: 15px;
          display: none;
          h3{
            padding-bottom: 0;
          }
        }
        ul{
          white-space: nowrap;
          overflow-x: auto;
          margin-top: 0;
          li{
            display: inline-block;
            a{
              padding: 15px 15px;
              &.active{
                text-decoration: underline;
              }
            }
            &:last-child{
              padding-right: 60px;
            }
          }
        }
      }
      .generate-finance-heading{
        padding: 10px !important;
        .beta{
          margin-bottom: 0 !important;
        }
        .review-btn{
          float: none;
          margin: 10px 0;
        }
        .review-wrapper-block{
          ul{
            li{
              display:  block;
              margin: 10px 0;
            }
          }
        }
      }
      .fixed-component-bar{
        display: none;
      }
      .review-module-wrapper{
        margin: 0 !important;
        margin-left: 15px !important;
        .card-body{
          table{
            tr{
              table-layout: auto;
              th,td{
                min-width: 160px;
              }
            }
          }
        }
      }
      .info-card{
        margin: 0;
        margin-right: -5px;
      }
    }
  }
}

@media (max-width: 576px){
  .fleet-dashboard{
    .col1{
      .assets-row{
        .widget-cards{
          &:nth-of-type(even){
            margin-left: 5px !important;
          }
        }
      }
    }
  }

  .fleet-contracts-db, .fleet-records-db, .fleet-maintenance-db{
    .col1{
      .assets-row{
        .widget-cards{
          margin-left: 0 !important;
          margin-right: 0 !important;
          &:nth-of-type(even){
            margin-left: 5px !important;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1200px){
  body{
    .fleet-records-db, .fleet-technical-db, .fleet-maintenance-db{
      margin-left: 30px;
      .db-back-arrow{
        left: 40px;
      }
    }
  }
}



