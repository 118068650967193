@import '../../../../assets/styles/setting';
body{
  .table-container{
    border: 1px solid #c6cddd;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
    border-radius: 3px 3px 0 0;
    margin-bottom: 40px;
    position: relative;
    table{
      width: 100%;
      background: #fff;
      thead{
        background: rgba(255,255,255,0.07);
        background: -moz-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(237,237,237,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(0,0,0,0.07)), color-stop(100%, rgba(0,0,0,0)));
        background: -webkit-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
        background: -o-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
        background: -ms-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
        background: linear-gradient(to bottom, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 );
        th{
          font-family:$ibmplexRegular;
          font-size:12px;
          color: #8c9ba5;
          padding: 15px;
        }
      }
      tbody{
        tr{
          cursor: pointer;
          transition:all 0.4s ease;
          &:nth-child(odd){
            background: #EBEFF6;
          }
          &:nth-child(even){
            background: #f5f7fa;
          }
          &:hover{
            background: #D0D9E8;
            .delete{
              opacity: 1;
            }
          }
          td{
            padding: 15px;
            font-family:$ibmplexRegular;
            font-size:14px;
            color: #000;
            span{
              font-family:$ibmplexRegular;
              font-size:14px;
              color: #000;
            }
          }
        }
      }
    }
  }

  .mr-calc-sidebar{
    top: 94px;
  }
  .assembly-timelinse-list{
    position: relative;
    padding-left: 20px;
    .table-title{
      background: #ffffff;
      padding: 15px;
      font-size: 20px;
      color: #083c7b;
    }
    table{
      .th-highlight{
        font-size: 14px;
        color: #bd10e0;
      }
      tr{
        td{
          &:first-child{
            font-size: 12px;
            color: #8c9ba5;
          }
        }
      }
    }
    .assembly-timeline-bar{
      position: absolute;
      top: -20px;
      left: 0;
      width: 1px;
      height: 95.67%;
      background: #5596E6;
    }
    .events-list{
      background: #fff;
      padding: 15px 20px;
      margin-bottom: 60px;
      position: relative;
      .dots-marker{
        width: 10px;
        height: 10px;
        position: absolute;
        top: -23px;
        left: -25px;
        border-radius: 50%;
        background: #5596E6;
        display: inline-block;
      }
      .event-date{
        position: absolute;
        left: 0;
        top: -28px;
        font-size: 16px;
        color: #9439E4;
        font-family:$ibmplexRegular;
      }
      h4{
        font-size: 18px;
        color: #000;
        font-family:$ibmplexRegular;
      }
      span{
        font-size: 10px;
        color: #7A7A7A;
        font-family:$ibmplexRegular;
      }
      h6{
        span{
          font-size: 14px;
          color: #000;
          font-family:$ibmplexRegular;
        }
      }
    }
  }
  .mr-rate-inner-wrapper{
    padding: 0 15px;
    margin-left: 60px;
  }
  .assembly-view-layout{

    .asset-list-font-size{
      h6{
        font-size: 14px;
      }
      .pretty-number-override-css {
       font-family: $ibmplexRegular;
       font-size: 14px;
     }
    }
    .nav-tabs{
      margin-bottom: 30px;
      .nav-item{
        .nav-link{
          padding: 10px 20px 10px;
          font-family:$ibmplexRegular;
          font-size: 15px;
          color: #8C9BA5;
          border: none;
          border-bottom: 1px solid #dee2e6;
          cursor: pointer;
          &.active{
            font-family:$ibmplexSemiBold;
            color: #2f8cff;
            border-bottom: 1px solid #2f8cff;
          }
          &:hover{
            color: #2f8cff;
            border-bottom: 1px solid #2f8cff;
          }
        }
      }
    }
    .shop-visit-status{
      margin-bottom: 20px;
      span{
        font-size: 12px;
        font-family:$ibmplexRegular;
        padding-right: 16px;
      }
      ul{
        li{
          background: #FAF4E7;
          padding:10px;
          button{
            display: inline-block;
            margin-right: 16px;
          }
        }
      }
    }
    .card-body{
      background: #fff;
      box-shadow: 0 2px 4px rgba(0,0,0,0.5);
      margin-bottom: 30px;
      position: relative;
      .edit-icon{
        position: absolute;
        right: 14px;
        top: 2px;
        cursor: pointer;
        img{
          width: 16px;
        }
      }
      .label-card{
        margin-bottom: 20px;
      }
      h6.label-title{
        font-size: 12px;
        color: #626262;
        font-family: $ibmplexRegular;
      }
      span.label-value{
        font-size: 14px;
        color: #000;
        font-family: $ibmplexRegular;
      }
    }
    .shop-visit-details{
      .title-info{
        margin-bottom: 20px;
        img{
          float: left;
          width: 20px;
        }
        h4{
          font-size: 20px;
          color: #000;
          font-family: $ibmplexLight;
          display: inline-block;
        }
        p{
          font-size: 14px;
          color: #BD10E0;
          font-family: $ibmplexRegular;
        }
      }
    }
    .add-claims-section{
      .add-claim-cta{
        overflow: hidden;
        margin-bottom: 20px;
        h4{
          font-size: 20px;
          color: #000;
          font-family: $ibmplexLight;
          display: inline-block;
        }
        button{
          float: right;
        }
      }
      .claim-payout-detail{
        background: #F7F3DD;
        border: 1px solid #DDD4A6;
        margin: 0 -20px -20px;
        padding: 20px 15px 4px;
        position: relative;
        .edit-icon{
          position: absolute;
          right: 14px;
          top: 2px;
          cursor: pointer;
          img{
            width: 16px;
          }
        }
        h4{
          font-size: 16px;
          color: #054289;
          font-family: $ibmplexRegular;
          margin-bottom: 20px;
        }
      }
    }
    .llp-table{

      .add-item{
        text-align:right;
        margin-bottom:15px;
        a{
          padding: 7px 30px;
          color:$color_fff;
          display:inline-block;
        }

      }
      .overflow-block{
        width:100%;
        overflow: auto;
        &::-webkit-scrollbar{
          display: none;
        }
        margin-bottom:30px;
        .table-outer-wrap{
          width: 100%;
          span{
            font-family: $ibmplexSemiBold;
            font-size: 12px
          }
          h6{
            font-family: $ibmplexRegular;
            font-size: 12px
          }
          .table-header{
            margin: 0;
            flex-shrink: 0;
            flex-grow: 0;
            flex-wrap: nowrap;
            border-left: 1px solid #dfe3e6;
            border-right: 1px solid #dfe3e6;
            >div{
              padding: 20px;
              background: #f0f2f3;
              padding: 10px 20px;
              border-right: 1px solid #dfe3e6;
              background: #f0f2f3;
              border-top: 1px solid #dfe3e6;
              border-bottom: 1px solid #dfe3e6;
            }


          }
          .table-body{
            margin: 0;
            flex-shrink: 0;
            flex-grow: 0;
            flex-wrap: nowrap;
            border-left: 1px solid #dfe3e6;
            border-right: 1px solid #dfe3e6;
            >div{
              padding: 20px;
              background: #f0f2f3;
              padding: 10px 20px;
              border-right: 1px solid #dfe3e6;
              background: #f0f2f3;
              border-bottom: 1px solid #dfe3e6;
            }
          }
        }
      }
    }
    .engine-sv-card{
      background: #fff;
      border: 1px solid #c6cddd;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
      border-radius: 3px;
      margin-bottom: 15px;
      .text-label{
        font-family: $ibmplexRegular;
        font-size: 12px;
        color: #a0a0a0;
        display: inline-block;
        line-height: 16px;
      }
      .inner-row{
        margin-bottom: 12px;
      }
      .para-ui, label{
        font-family: $ibmplexRegular;
        font-size: 14px;
        color: #000;
      }
      .tab-content{
        padding: 0 15px 15px 15px;
      }
      .workscope-wrapper-block{
        position: relative;
        background: #f5f7fa;
        border: 1px solid #c6cddd;
        border-radius: 3px;

        h6{
          font-size: 16px;
          font-family: $ibmplexRegular;
          color: #bd10e0;
        }

        .run-type-blk{
          background: #f0f2f3;
          padding: 15px;
          border-bottom: 1px solid #d9d9d9;
        }
        .workscope-blk, .restoration-blk, .llp-replacement-blk{
          background: #fff;
          padding: 15px;
          border-bottom:1px solid #d9d9d9;
        }
        .total-amt-blk{
          padding: 15px;
          background: #f0f2f3;
        }
      }
    }

    .no-records-found{
      margin:0;
    }
  }
  .engine-workscope-table{
    background: #fff;
    input[type="text"]{
      width: 90%;
      border-bottom: 1px solid #d7d7d7;
    }
    .add-new-row{
      font-size: 12px;
      font-family: $ibmplexRegular;
      display: block;
      cursor: pointer;
      margin-top: 10px;
      color: #2f8cff;
    }
    .workscope-row{
    margin:0;
      border-bottom: 1px solid #d7d7d7;
      .module-col{
        position: relative;
        .module-content{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 90%;
        }
      }
      .workscope-sub-row{
        border-bottom: 1px solid #d7d7d7;
        &:last-child{
          border-bottom: none;
        }
        .submodule-col{
          border-left: 1px solid #d7d7d7;
          position: relative;
          .submodule-content{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 90%;
          }
        }
        .workscope-item-row{
          width:100%;
          border-bottom: 1px solid #d7d7d7;
          &:last-child{
            border-bottom: none;
          }
          .item-col{
            padding-top: 10px;
            padding-bottom: 10px;
          }
          .workscope-level{
            padding:0 10px;
          }

          .del-row{
            color:#da0c0c;
          }
        }
      }
    }
  }
  .workscope-table-heading{
    padding:0 !important;
    text-align: center;
    h6{
      color:#000000!important;
      vertical-align: baseline;
    }
    .workscope-table-col{
      border: 1px solid #dfe3e6;
    }
    .workscope-level{
      padding:0 !important;
      h6{
        border-bottom:1px solid #dfe3e6;

      }
      div{

          h6{
            border-bottom:0;

          }
      }
    }
  }
  .workscope-button{
    padding-bottom: 10px;
  }

  .workscope-sub-row{
    width:100%;
  }
  .sv-reject-modal{
    form{
      width: 400px;
      margin: 0 auto;
      textarea{
        border-bottom: 1px solid #d1d1d1;
        display: block;
        margin-bottom: 20px;
        width: 100%;
        height: 150px;
      }
      input[type="submit"]{
        display: block;
        width: 100%;
      }
    }
  }
  .claims-block{
    .no-records-found{
      margin:0;
    }
  }

}
@media (max-width: 575.98px) {
  body{
    .mr-rate-wrapper.maintainance-container{
      margin-left: 0 !important;
      margin-top: 230px !important;
      .mr-rate-inner-wrapper{
        padding: 0;
        .maintainenace-listing-blk{
          overflow-x: auto;
          .asset-list-filter-sort{
            >div, .asset-list{
              width: auto;
              display: inline-block !important;
            }
            .filter-block, .asset-list div{
              >div{
                display: inline-block;
                width: 150px;
              }
            }
            .filter-block, .asset-list div{
              display: block;
              white-space: nowrap;
            }

          }
          .asset-list{
            width: auto;
            display: inline-block !important;
          }
          .asset-list div{
            >div{
              display: inline-block;
              width: 150px;
            }
          }
          .asset-list div{
            display: block;
            white-space: nowrap;
          }
          .no-records-found{
            width: 750px;
          }
          &.asset-list-filter-sort{
            >div, .asset-list{
              width: auto;
              display: inline-block !important;
            }
            .filter-block, .asset-list div{
              >div{
                display: inline-block;
                width: 150px;
              }
            }
            .filter-block, .asset-list div{
              display: block;
              white-space: nowrap;
            }
            .no-records-found{
              width: 600px;
            }
          }
        }
      }
      .mr-calc-sidebar{
        opacity: 1;
        visibility: visible;
        height: 90px;
        z-index: 91;
        left: 60px;
        top: 135px !important;
        height: 125px;
        .mbl-cross{
          display: none;
        }
        h5{
          margin-top: 10px;
          margin-bottom: 0px;
        }
        ul{
          white-space: nowrap;
          overflow-x: auto;
          margin: 0 !important;
          li{
            display: inline-block;
            a{
              padding: 15px 15px;
              &.active{
                text-decoration: underline;
              }
            }
            &:last-child{
              padding-right: 60px;
            }
          }
        }
      }

    }
  }
}
