@import '../../../../../assets/styles/setting';
// common scss
.left-margin20{
  margin-left:20px !important;
}
.contract-element{
  display: block;
  margin:0 15px;
  .terminate{
    font-size: 13px;
    color:#f50057;
    opacity: 0;
  }
  &:hover{
    .terminate{
      opacity: 1;
    }
  }
}
.data-points-heading{
  position: absolute;
  font-size: 12px;
  display: inline-block;
  background: #b18a59bf;
  color: #fff;
  padding: 2px 5px;
  border-radius: 10px;
  z-index: 99;
  bottom: 10px;
  left: 30px;
}

.log-listing-block{
  .add-contract{
    margin: 0 15px 0 60px;
  }

  .add-enquiry-block{
    text-align: right;
    .btn-group{
      margin-right:10px;
    }
  }
  .block-title{
      @include elementStyles(16px, #bd10e0, normal, normal, $ibmplexRegular, null, null);
      margin-bottom: 3px;
  }
  .block-subs{
    @include elementStyles(13px, #838383, normal, normal, $ibmplexRegular, null, null);
  }
  .mobile-a
  .add-contract{
    margin: 0 15px 0 60px;
    text-align: right;
    a{
      color: #fff;
      padding: 6px 24px;
      border-radius: 3px;
      &:hover{
        color: #fff;
      }
    }
  }
  .logHeading{
    padding: 15px;
    margin: 0;
    background:#fafafa;
    border:1px solid #dfe3e6;
    h6{
      font-family:$ibmplexRegular;
      color:#6d9bb9;
      .asc-sort,  .desc-sort{
        font-size: 7px;
        color:#dfdfdf;
        cursor: pointer;
      }
      .asc-sort{
        position: relative;
        right: -8.5px;
        top: -4px;
        font-size: 7px;
      }
      .desc-sort{
        position: relative;
        top: 3px;
      }
    }
  }
  .logList{
    margin: 0;
    padding:15px;
    background:#ffffff;
    border:1px solid #dfe3e6;
    border-top:none;
    transition: all 0.5s ease;
    &:hover{
      background: #f5f5f5;
      .delete-icon{
        display: block;
      }
    }
    .delete-icon{
      cursor: pointer;
      position: absolute;
      right:0;
      top:5px;
      margin-left:6px;
      display: none;
    }
    p{
      font-family:$ibmplexRegular;
      color:#8b8d91;
      img{
        display: inline-block;
        &.tick-icon{
          margin-right:6px;
        }
      }
    }
  }
  .asset-status{
    text-transform: uppercase;
    font-family:$ibmplexSemiBold;
    border-radius: 4px;
    font-size: 10px;
    padding: 4px 0 2px;
    display: inline-block;
    width: 100px;
    text-align: center;
    line-height: 12px;
  }
}
.delete-logs{
  text-align: center;
  h4{
    font-family:$ibmplexRegular;
    font-size: 16px;
    margin-bottom: 20px;
    .bold{
      font-family: $ibmplexSemiBold;
    }
  }
  ul{
    li{
      button{

      }
    }
  }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
.contract-heading{
  margin:30px 15px 0 !important;
  }
}

@media (min-width: 1200px) {
  .contract-heading{
    margin:30px 15px 0 !important;
    }
}
@media (max-width: 575.98px) {

.log-listing-block{
  
  .mobile-asset-list{
    overflow: auto;
    &::-webkit-scrollbar{
      display: none;
    }
    &.contract-list-mbl-spac{
      padding-top:40px;
    }
    .contract-heading{
      width: 235%;

      margin-left:0 !important;

    }
    .contract-element{
      width: 235%;
      margin-left:0;
    }
  }
}
}
