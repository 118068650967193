@import '../../../assets/styles/setting';
.content-toptip{
  color: red;
}
.common-table{
 width: 100%;
 background: #fff;
 thead{
   background: rgba(255,255,255,0.07);
   background: -moz-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
   background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(237,237,237,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(0,0,0,0.07)), color-stop(100%, rgba(0,0,0,0)));
   background: -webkit-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
   background: -o-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
   background: -ms-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
   background: linear-gradient(to bottom, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 );
   th{
     font-family:$ibmplexRegular;
     font-size:12px;
     color: #8c9ba5;
     padding: 15px;
     &.sticky-background{
      background-color: #F4F4F4;
      z-index: 1;
    }
   }
 }
 tbody{
   tr{
     cursor: default;
     transition:all 0.4s ease;
     &:nth-child(odd){
       background: #EBEFF6;
     }
     &:nth-child(even){
       background: #f5f7fa;
     }
     &:hover{
       background: #D0D9E8;
     }
     td{
       padding: 15px;
       span{
         font-family:$ibmplexRegular;
         font-size:14px;
         color: #000;
       }
       input{
        background: transparent;
       }
       hr{
        margin: 0;
        border-color: #2f8cff;
        width: 15px;
        border-style: dashed;
       }
       input[type="text"]{
         background: transparent;
         border-bottom: 1px solid #b9b7b7;
         width:100%;
         &.table-input{
           border-bottom: 1px solid #b9b7b7;
           width:100%;
         }

       }
     }
   }
 }
}
