@import '../../../../../assets/styles/setting';
body{
  .disclaimer-container{
    display: block;
    padding: 40px 40px 30px 40px;
    border: 1px solid #000;
    border-radius: 5px;
    label{
      display: block;
      font-size: 16px;
      color: #000;
    }
    textarea{
      margin-top: 15px!important;
      width: 100%!important;
    }
  }
  .edit-view-tech-spec{
    // position: relative;
    // z-index: 2;
    .blue-title{
      font-family:$ibmplexRegular;
      font-size: 16px;
      color: #cfafea;
      padding-bottom: 15px;
    }
    .horizontal-card{
      margin-bottom:0 !important;
    }
    h6{
      font-family:$ibmplexRegular;
      font-size:12px;
      color: #a0a0a0;
    }
    span{
      font-family:$ibmplexRegular;
      font-size:14px;
      color:$color_000;
      line-height:20px;
    }
    .tech-summary-heading{
      padding-left: 0;
      margin-left: -55px;
    }
    .nav-tabs{
      .nav-item{
        .nav-link{
          background: transparent;
          border: 0;
          cursor: pointer;
          background: transparent;
          border-color: transparent;
          font-family:$ibmplexRegular;
          font-size: 14px;
          text-transform: uppercase;
          &.active{
           color: #3f51b5;
           border-bottom: 1px solid #3f51b5;
         }
       }
     }
   }
   .tech-engine-tab{
     .nav-link{
       font-size: 12px !important;
     }
   }
   .tab-content{
      padding: 20px 0;
      input[type="text"]{
        background: transparent;
      }
      table{
        width:100%;
        td{
          font-family:$ibmplexRegular;
          font-size:14px;
          color:#8b8d91;
          line-height:20px;
        }
      }
      .general-block{
        input[type="text"]{
          background: transparent;
          border-bottom: 1px solid #b9b7b7;
          width:100%;
          &.table-input{
            border-bottom: 1px solid #b9b7b7;
            width:100%;
          }

        }
        hr{
          margin: 0;
          border-color: #2f8cff;
          width: 15px;
          border-style: dashed;
        }
        .general-inner-wrap{
          margin-bottom:20px;
        }
        .table-block{
          th, td{
            color:#8c9ba5;
            font-size: 12px;
            font-family: $ibmplexRegular;
            padding: 10px 0px 0px 0px;
            text-align: center;
            table{
              th, td{
                padding: 10px;
              }
            }
          }
          td{
            font-family: $ibmplexRegular;
            font-size: 14px;
            color: $color_000;
          }
          &.tech-spec-table-block{
            >div{
              padding:0;
            }
            th, td{
              color:#a0a0a0;
              color:#8c9ba5;
              font-size: 12px;
              font-family: $ibmplexRegular;
              padding: 10px 0px 0px 0px;
              text-align: center;
              table{
                th, td{
                  padding: 10px;
                }
              }
            }
            input{
              border-bottom:1px solid #8c9ba5;
            }
            .form-group{
              margin: 0 0 20px;
              padding: 0;

            }
          }
          &.overview-table{
            border-top: 1px solid #ddd;
            padding-top: 15px;
            margin-top:25px;
            table{
              margin-top:15px;
              tbody{
                th{
                  width:200px;
                }
              }
            }
          }
        }
      }
      .engine-form{
         background:#f5f7fa;
         border:1px solid #dfe3e6;
         border-radius:3px;
         padding:15px;
         margin-bottom:20px;
         h3{
           margin-bottom:20px;
         }
         .engine-inner-wrap{
           margin-bottom:20px;
         }
       }
     }

     .asset-list-filter-sort{
       .filter-block{
         border-right:0;
         border-left:0;
       }
     }
     .asset-list{
       border-right:0;
       border-left:0;
     }
   }
 }
 .titled-fitted{
   padding: 1px 6px;
   width: 100%;
   text-align: center;
   font-size: 10px;
   text-transform: uppercase;
   color: #fff;
   background: #b1b1b1;
   font-size: 8px;
   border-radius: 4px;
   margin-bottom: 0px;
   letter-spacing: 0.5px;
 }
 .tech-spec-utilization-edit-block{
   h6{
     margin: 30px 0px 10px 25px;
     color: #9439e4 !important;
   }
 }
 .tech-spec-off-wing-block{
   background: #FAF4E7;
   margin: 0px 20px 20px;
   padding: 0px;
   border:1px dotted #f1e1c0;
   textarea{
    background: transparent;
  }

 }
 .asset-list-util{
  padding:0 !important;
  border-bottom:0 !important;
  border: 1px solid #dfe3e6;
  &:last-child{
    // border-bottom: 1px solid #dfe3e6 !important;
  }
  .cashflow-row-util{
    margin:0;
    .cash-info{
      padding:6px;
      border-bottom: 1px solid #dfe3e6;
      height: 50px;
    }
    .border-col{
      border-left: 1px solid #dfe3e6;
      &:first-child{
        border-left:0 !important;
      }
    }
  }
}


 // Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .edit-view-tech-spec{
    .nav, .nav-tabs{
      overflow: auto;
      &::-webkit-scrollbar{
        display: none;
      }
      white-space: nowrap;
      flex-wrap: nowrap;
    }
    .table-container{
      overflow: auto;
      &::-webkit-scrollbar{
        display: none;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .edit-view-tech-spec{
      .overview-table{
      table{
        width:100%;
      }
    }

    .mobile-table-format{
      overflow: auto;
      &::-webkit-scrollbar{
        display: none;
      }
      .asset-list-filter-sort{
        width:366%;
        .filter-block{
          display: flex;
          flex-shrink: 1;
          flex-grow: 1;
          >div{
            width:200px;
          }
        }
      }
      .asset-list{
        width:366%;
        .flex-centered{
          flex-shrink: 1;
          flex-grow: 1;
          >div{
            width:200px;
          }
        }
      }
    }
  }

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .edit-view-tech-spec{
    margin-left: 15px;
    .nav, .nav-tabs{
      margin-left:15px;
    }
    .tab-content{
      margin-left: 15px;
      .overview-table{
        table{
          width:50%;
        }
      }


    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .edit-view-tech-spec{
    margin-left: 15px;
    .overview-table{
      table{
        width:50%;
      }
   }
  }
}


.export-import-list{
  position: absolute;
  top: 10px;
  right:5px;
  display: inline-block;

  img.export-icn{
    width: 14px;
    margin-right: 5px;
  }
  a {
    color: #194886;
    z-index: 9;
    cursor: pointer !important;
    font-size: small;
  }

  .import-link{
      position:relative;
      z-index: 10;
      cursor: pointer;
      color:  #194886 !important;
      input[type="file"]{
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;

      }
    }
  }
