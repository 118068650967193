@import '../../../assets/styles/setting';

.timeline-container{
    border-radius: 10px;
    margin: 0 20px;
    -webkit-box-shadow: 13px 10px 50px -10px rgba(0,0,0,0.14);
  -moz-box-shadow: 13px 10px 50px -10px rgba(0,0,0,0.14);
  box-shadow: 13px 10px 50px -10px rgba(0,0,0,0.14);
  } 
.timeline{
    margin: 0;
    height: 100%;
   ul{
     list-style: none;
     margin-left: 20px;
     padding: 0;
     border-left:1px dashed #2f8cff ;
     li{
      margin: 30px 20px;
      .h2{
        margin-bottom: 10px;
        margin-bottom: 10px;
        padding-top: 10px;
      }

      .timeline-circle{
       border: 1px groove #2f8cff ;  
       border-radius: 100%;
       background: #fff;
       width: 20px;
       height: 20px;
       position: relative;
       top:0;
       left: -50px;

     }
     p{
       justify-content: start;
       margin: 10px 0;
       padding: 10px 0;
       font-size: 12px;
     }
     .timeline-time{
       margin: -17px 0 10px 0px;
     }
     
    }

     }
    
   
}
