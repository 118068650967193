@import '../../../assets/styles/setting';

body{
  .login-wrap{
    .is-focused{
      color:#333;
      .Select-value-label{
        color:#333 !important ;
      }
    }

    .Select-value-label{
      color:#fff !important;
    }

    .logo-block{
      background: url('https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/login_background.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;

      .acumen-logo{
        margin-top:30px;
        width:100%;
        img{
          width: 65px;
        }
      }
      .aims-logo{
        width:100%;
        img{
          width:90px;
          margin-bottom:18px;
        }
        h1{
          font-family: $ibmplexSemiBold;
          color: $color_fff;
        }
      }
      .contribute-block{
        img{
          display: block;
          width: 120px;
          margin-bottom: 6px;
          background: #fff;
          padding: 4px 8px;
          border-radius: 4px;
        }
        p{
          font-family:$ibmplexRegular;
          bottom:10px;
          font-size:10px;
          color:$color_fff;
          a{
            color:$color_fff;
          }
        }
      }
    }
    .rh-block{
      background-image:linear-gradient(-180deg, #6538db 0%, #0a56a8 100%);
      .form-block{
        width: 400px;
        margin: 0 auto;
        .reset-block{
          h6{
            font-family:$ibmplexLight;
            font-size:12px;
            color:$color_fff;
            &.back-icon{
              margin-bottom:4px;
            }
            &.send-link-block{
              margin-bottom:25px;
              line-height: 16px;
            }
          }
        }
        h2{
          font-family:$ibmplexLight;
          color:$color_3ff;
        }
        .login-heading-block{
          margin-bottom: 45px;
          h2{
            &.wc-login-block{
              margin-bottom:4px;
            }
          }
          h6{
            font-family: $ibmplexRegular;
            color:$color_fff;
          }
        }
        form{
          .form-group{
            margin-bottom:30px;
          }
          label{
            font-family:$ibmplexRegular;
            font-size:12px;
            color:$color_fff;
            display:block;
            margin-bottom:4px;
          }
          input[type="text"], input[type="password"]{
            border-bottom: 1px solid $color_6f0 ;
            background: transparent;
            width: 100%;
            color:$color_fff;
            font-family:$ibmplexRegular;
            font-size: 16px;
          }
          input[type="submit"], button {
            font-family:$ibmplexSemiBold;
            font-size:12px;
            color:$color_1b2;
            padding:13px 0;
            background:$color_fff;
            border-radius:3px;
            width: 100%;
            margin-bottom:40px;
            cursor: pointer;
          }
        }
        a{
          cursor: pointer;
          display:block;
          font-family:$ibmplexRegular;
          font-size:12px;
          color:$color_fff;
          &.request-access{
            text-align: center;
            text-decoration: underline;
          }
          &.forget-pass{
            text-align: right;
            margin-top:9px;
          }
        }
      }
      .condition-block{
        width: 100%;
        text-align: center;
        p{
          text-align: center;
          padding-right: 0;
          display: inline !important;
          font-size: 12px;
          font-family:$ibmplexRegular;
          color: #7f8fa4;
          border-bottom: 1px solid #7f8fa4;
        }
        a{
          display:block;
          font-size: 12px;
          font-family: $ibmplexRegular;
          margin-bottom: 6px;
        }
      }
      .privacy-policy{
        margin-top:15px;
      }
    }
  }
  .reset-password-block{
    background-image: url('https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/phase-2/banner-min.jpg');
    margin: 0 -15px;
    min-height:100vh;
    padding:40px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    display: -webkit-flex;
    flex-flow: wrap;
    -webkit-flex-flow: wrap;
    align-items: center;
    &:before,&:after{
      width: 0;
    }
    .reset-block{
      background:#ffffff;
      border:1px solid #979797;
      border-radius:6px;
      width:540px;
      margin:0 auto;
      padding: 20px 15px;
      h2{
        @include elementStyles(24px, #4f6276, normal, normal, $ibmplexRegular, null, null);
        margin-bottom: 10px;
      }
      h6{
        &.password-subheading{
          @include elementStyles(16px, #212529, normal, normal, $ibmplexRegular, null, null);
          margin-bottom: 30px;
        }
      }
      .form-group{
        margin-bottom:28px;
        label{
          @include elementStyles(12px, #000000, normal, normal, $ibmplexRegular, null, null);
          cursor: pointer;
        }
        input{
          border-bottom:1px solid #979797;
          width:100%;
          height: 32px;
        }
      }
      input[type="submit"]{
        @extend %primary-botton;
        width: 200px;
        padding: 15px;
        @include elementStyles(14px, #ffffff, normal, normal, $ibmplexRegular, null, null);
        letter-spacing: 0.7px;
        margin-top: 15px;
        margin: 0 auto;
      }
      .back-icon{
        @include elementStyles(14px, #2893ff, 1.67, normal, $ibmplexRegular, null, null);
        display: inline-block;
        margin-bottom: 5px;
      }
      .back-to-login-block{
        @include elementStyles(14px, #2893ff, 1.67, normal, $ibmplexRegular, null, null);
        display: inline-block;
        margin-bottom: 5px;
        img{
          margin-bottom:15px;
          display: block;
        }
        p{
          font-family:$ibmplexRegular;
          font-size:16px;
          color:#417505;
          text-align:center;
        }
      }
      .back-btn{
        text-align: center;
        padding:7px 0;
        width:100%;
        margin-bottom:90px;
        display: block;
        text-align: center;
        font-family:$ibmplexRegular;
        font-size:12px;
        color:$color_fff;
      }
    }
    .password-grid{
      position: relative;
      input{
        padding-right: 25px;
      }
      i{
        position: absolute;
        top: 30px;
        right: 5px;
        cursor: pointer;
      }
    }
  }
}
//terms and conditions scss start //
.terms-conditions-sections {
  padding: 30px 15px 60px;
  width: 78%;
  margin: 0 auto;
  &.terms-condition-phase{
    h2 {
      font-family: $ibmplexSemiBold;
      font-size: 14px;
      color: #000;
      margin: 40px 0 10px;
      &.intro-heading{
        margin-top:0;
      }
  }
  p, span {
      font-family: $ibmplexRegular;
      font-size: 14px;
      color: #7a7a7a;
  }
  li{
    font-family: $ibmplexRegular;
    font-size: 14px;
    color: #7a7a7a;
  }
  }
  .term-inner-block{
    // width:95%;
    margin:0 auto;
  }
  h4.gama{
    margin: 0 0 15px ;
  }
  .col-md-12 {
      background-color: #fff;
      padding: 25px;
      box-shadow: 0 0 4px 0 #ccc;
      border-radius: 4px;
      ul, ol{
        margin-top: 10px !important;
        padding-left: 30px !important;
        font-family: $ibmplexRegular;
        font-size: 14px;
        color: #7a7a7a;
      }

      h1 {
          font-family: $ibmplexSemiBold;
          font-size: 16px;
          color: #000;
          margin-bottom: 20px;
      }
      h2 {
          font-family: $ibmplexSemiBold;
          font-size: 14px;
          color: #000;
          margin: 40px 0 10px;
          &.intro-heading{
            margin-top:0;
          }
      }
      p, span {
          font-family: $ibmplexRegular;
          font-size: 14px;
          color: #7a7a7a;
      }
      li{
        font-size: 14px;
      }
  }
}
///terms and conditions scss ends //
@media (max-width: 575.98px) {
  body{
    padding-left: 0 !important;
    .login-wrap{
      padding-bottom: 20px;
      .aims-logo{
        margin: 40px 0 60px;
      }
      .contribute-block{
        display: none;
      }
      .rh-block{
        padding-top: 20px;
        padding-bottom: 20px;
        .form-block{
          h2{
            font-size: 20px;
            line-height:32px;
            margin-bottom:35px;
            &.reset-password-heading{
              margin-bottom:4px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  body{
    padding: 0 !important;
    .login-wrap{

    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  body{
    padding: 0 !important;
    .login-wrap{

    }
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  body{
    padding-left:25px !important;
    .login-wrap{
      height:100vh;
      margin-left: -40px;
      .logo-block{
        padding:0 60px;
        .aims-logo{
          height:calc(100vh - 25vh);
        }
      }
      .rh-block{
        .form-block{
          h2{
            font-size: 24px;
            line-height:32px;
            margin-bottom:35px;
            &.reset-password-heading{
              margin-bottom:4px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  body{
    padding-left:25px;
    .login-wrap{
      height:100vh;
      margin-left: -40px;
      .logo-block{
        padding:0 60px;
        .aims-logo{
          height:calc(100vh - 25vh);
        }
      }
      .rh-block{
        .form-block{
          h2{
            font-size: 24px;
            line-height:32px;
            margin-bottom:35px;
            &.reset-password-heading{
              margin-bottom:4px;
            }
          }
        }
      }
    }
  }
}
