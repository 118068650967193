@import '../../../../assets/styles/setting';
body{
  .contract-headline-block{
    h6{
      font-family:$ibmplexRegular;
      font-size:12px;
      color:#a0a0a0;
    }
    span{
      font-family:$ibmplexRegular;
      font-size:14px;
      color:#000000;
      line-height:20px;
    }
    h4{
      font-family:$ibmplexRegular;
      font-size:20px;
      color:#000000;
      margin-bottom:24px;
      margin-top:10px;
    }
    h5{
      font-family:$ibmplexRegular;
      font-size:16px;
      color:#9439e4;
      padding-bottom:15px;
      .editIcon{
        float:right;
        cursor: pointer;
      }
    }
  }
  .card-block{
    margin-bottom:20px;
    background: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
    border-radius: 3px;
    padding: 25px 15px 15px;
  }
  .vertical-card{
    height:95%;
    ul{
      li{
        margin-bottom:20px;
        &.operator-same{
          margin-bottom:0;
          img{
            margin-right:5px;
          }
        }
      }
    }
  }
  .horizontal-card{
    .blue-img{
      cursor: pointer;
      float: right;
    }
    .horizontal-inner-block{
      margin-bottom: 20px;
    }
  }
}
