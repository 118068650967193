@import '/setting';
body{
  .drawer-container{
    transition: all .5s cubic-bezier(.075,.82,.165,1);
    .drawer-header{
      font-family: $ibmplexRegular;
      font-size: 16px;
      color: #9439e4;
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: 11px;
      margin: 20px 20px 30px;
      .heading{

      }
      .close-icon{
        color: #000000;
        position: relative;
        top: 3px;
        float: right;
        cursor: pointer;
      }
      ul{
        &.right-blk{
          display: inline-block;
          float: right;
          position: relative;
          bottom: 7px;
          right: 12px;
          .rev-history-title{
            text-transform: uppercase;
            color: #3f51b5;
            font-size: 13px;
            cursor: pointer;
            .audit-img{
              margin-right: 3px;
              width: 15px;
            }
          }
          .cta{
            cursor: pointer;
            font-size: 13px;
            color: #3f51b5;
            text-transform: uppercase;
            .edit-icon, .view-icon{
              width: 15px;
              margin-right: 3px;
              position: relative;
              top: 6px;
            }
          }
        }
      }
    }
    .drawer-body{
      margin: 20px 20px 60px;
      .MuiGrid-container{
        .MuiFormLabel-root{
          font-family: $ibmplexRegular;
          font-size: 18px;
          color: #959595;
        }
      }
    }
    .drawer-footer{
      position: fixed;
      bottom: 0;
      padding: 15px 20px;
      background: #fafafa;
      width: 100%;
      .MuiButton-root{
        font-family: $ibmplexRegular;
        padding: 4px 15px;
      }
    }
  }
  .add-lessee{
        .Select-value-label{
          font-family: $ibmplexRegular;
          font-weight: 800 !important;
        }
        .Select-clear-zone  {
          display: none;
        }
        :hover{
          .Select-clear-zone  {
            display: block;
            top:0px;
            right: 30px;
            .Select-clear{
              font-size: 22px !important;
            }
          }
        }
  }
}
